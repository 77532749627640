import axios from 'axios';
import Vue from 'vue';
import store from '../store/index';
import router from '../router/index';

const API_URL = process.env.VUE_APP_API_URL;
const IS_DEV = process.env.VUE_APP_IS_DEV;

const authInterceptor = (config) => {
  const locale = localStorage.getItem('locale');

  if (locale) {
    config.headers['Accept-Language'] = locale;
  }

  const token = Vue.$cookies.get('token');

  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }

  return config;
};

const saleshouseInterceptor = (config) => {
  const saleshouse = store.state.selectedSaleshouse;

  if (saleshouse && saleshouse.id) {
    config.headers['OML-Saleshouse-Id'] = saleshouse.id;
  }

  return config;
};

const timeOutMilliseconds = IS_DEV === 'TRUE' ? 600 * 1000 : 360 * 1000;

const config = {
  baseURL: API_URL,
  timeout: timeOutMilliseconds, // Timeout
};

const Axios = axios.create(config);

Axios.interceptors.request.use(authInterceptor);

Axios.interceptors.request.use(saleshouseInterceptor);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const code = error?.response?.status ? parseInt(error.response.status) : undefined;

    if (error.config.method == 'get') {
      if (code === 401) Vue.notify({ type: 'error', title: 'Unauthorized', text: error, duration: 3000 });
      else if (code === 403) Vue.notify({ type: 'error', title: 'Forbidden', text: error, duration: 3000 });
      else Vue.notify({ type: 'error', title: error, duration: 3000 });
      //if (!(code === 404 && error.config.url.contains('/fact-grps/bind')))
    }

    if (code === 401) {
      if (Vue.$cookies.get('token')) Vue.$cookies.remove('token');
      if (router.currentRoute.fullPath !== '/') {
        store.commit('clearUser');
        store.commit('clearChannelsList');
        store.commit('clearBrandsList');
        store.commit('clearSalesHousesList');
        store.commit('clearSelectedSaleshouse');
        store.commit('clearAdvertisersList');
        store.commit('clearAgenciesList');
        store.commit('clearProjects');
        localStorage.removeItem('currentUserPermissions');
        localStorage.removeItem('currentUserRole');
        const currentPath = window.location.pathname + window.location.search;
        router.push({ path: '/', query: { redirect: currentPath } });
      }
    }

    if (
      (code === 401 && error.config.method == 'post') ||
      code === 422 ||
      code === 400 ||
      code === 403 ||
      code === 404 ||
      code === 503 ||
      (process.env.NODE_ENV !== 'production' && code === 500)
    ) {
      throw error.response;
    }

    // if (code == 503) {
    //   store.commit('maintenance', true)
    //   app.router.push(app.localeRoute({ name: 'maintenance' }))
    //   throw error.response
    // }

    return Promise.reject(error);
  }
);

//Auth
export function postLogin(data) {
  const url = `${API_URL}/auth/login`;
  return Axios.post(url, data);
}
export function postMe() {
  const url = `${API_URL}/auth/me`;
  return Axios.post(url);
}
export function postLogout() {
  const url = `${API_URL}/auth/logout`;
  return Axios.post(url);
}
export function postRefresh() {
  const url = `${API_URL}/auth/refresh`;
  return Axios.post(url);
}

//Agencies
export function getAgencies(data) {
  const url = `${API_URL}/agencies`;
  return Axios.get(url, { params: data });
}

export function postAgencies(data) {
  const url = `${API_URL}/agencies`;
  return Axios.post(url, data);
}

export function getAgenciesId(data) {
  const url = `${API_URL}/agencies/${data}`;
  return Axios.get(url);
}

export function putAgenciesId(id, data) {
  const url = `${API_URL}/agencies/${id}`;
  return Axios.put(url, data);
}

export function deleteAgenciesId(data) {
  const url = `${API_URL}/agencies/${data}`;
  return Axios.delete(url);
}

export function putRestoreAgenciesId(id) {
  const url = `${API_URL}/agencies/${id}/restore`;
  return Axios.put(url);
}

export function putAllowMassCopyAgencies(data) {
  const url = `${API_URL}/agencies/allow-mass-copy`;
  return Axios.put(url, data);
}

//Advertiser
export function getAdvertisers(data) {
  const url = `${API_URL}/advertisers`;
  return Axios.get(url, { params: data });
}

export function postAdvertisers(data) {
  const url = `${API_URL}/advertisers`;
  return Axios.post(url, data);
}

export function getAdvertisersId(data) {
  const url = `${API_URL}/advertisers/${data}`;
  return Axios.get(url);
}

export function putAdvertisersId(id, data) {
  const url = `${API_URL}/advertisers/${id}`;
  return Axios.put(url, data);
}

export function deleteAdvertisersId(data) {
  const url = `${API_URL}/advertisers/${data}`;
  return Axios.delete(url);
}

//BrandClass
export function getClasses(data) {
  const url = `${API_URL}/brand_classes`;
  return Axios.get(url, { params: data });
}

export function postClasses(data) {
  const url = `${API_URL}/brand_classes`;
  return Axios.post(url, data);
}

export function getClassesId(data) {
  const url = `${API_URL}/brand_classes/${data}`;
  return Axios.get(url);
}

export function putClassesId(id, data) {
  const url = `${API_URL}/brand_classes/${id}`;
  return Axios.put(url, data);
}

export function deleteClassesId(data) {
  const url = `${API_URL}/brand_classes/${data}`;
  return Axios.delete(url);
}

// Brand Groups
export function getGroup(data) {
  const url = `${API_URL}/brand_groups`;
  return Axios.get(url, { params: data });
}

export function postGroup(data) {
  const url = `${API_URL}/brand_groups`;
  return Axios.post(url, data);
}

export function putGroupId(id, data) {
  const url = `${API_URL}/brand_groups/${id}`;
  return Axios.put(url, data);
}

export function deleteGroupId(data) {
  const url = `${API_URL}/brand_groups/${data}`;
  return Axios.delete(url);
}

//USERS
export function getUsers(data) {
  const url = `${API_URL}/users`;
  return Axios.get(url, { params: data });
}

export function postUsers(data) {
  const url = `${API_URL}/users`;
  return Axios.post(url, data);
}

export function getUsersId(data, id) {
  const url = `${API_URL}/users/${id}`;
  return Axios.get(url, { params: data });
}

export function putUsersId(id, data) {
  const url = `${API_URL}/users/${id}`;
  return Axios.put(url, data);
}

export function deleteUsersId(data) {
  const url = `${API_URL}/users/${data}`;
  return Axios.delete(url);
}

export function putUsersRestore(data) {
  const url = `${API_URL}/users/${data}/restore`;
  return Axios.put(url);
}

//Salehouses
export function getSalehouses(data) {
  const url = `${API_URL}/saleshouses`;
  return Axios.get(url, { params: data });
}

export function postSalehouses(data) {
  const url = `${API_URL}/saleshouses`;
  return Axios.post(url, data);
}

export function getSalehousesId(data) {
  const url = `${API_URL}/saleshouses/${data}`;
  return Axios.get(url);
}

export function putSalehousesId(id, data) {
  const url = `${API_URL}/saleshouses/${id}`;
  return Axios.put(url, data);
}

export function deleteSalehousesId(data) {
  const url = `${API_URL}/saleshouses/${data}`;
  return Axios.delete(url);
}

//Measurements
export function getMeasurements(data) {
  const url = `${API_URL}/measurement_companies`;
  return Axios.get(url, { params: data });
}

export function postMeasurements(data) {
  const url = `${API_URL}/measurement_companies`;
  return Axios.post(url, data);
}

export function getMeasurementsId(data) {
  const url = `${API_URL}/measurement_companies/${data}`;
  return Axios.get(url);
}

export function putMeasurementsId(id, data) {
  const url = `${API_URL}/measurement_companies/${id}`;
  return Axios.put(url, data);
}

export function deleteMeasurementsId(data) {
  const url = `${API_URL}/measurement_companies/${data}`;
  return Axios.delete(url);
}

//  Target
export function getTarget(data) {
  const url = `${API_URL}/target_audiences`;
  return Axios.get(url, { params: data });
}

export function postTarget(data) {
  const url = `${API_URL}/target_audiences`;
  return Axios.post(url, data);
}

export function getTargetId(data) {
  const url = `${API_URL}/target_audiences/${data}`;
  return Axios.get(url);
}

export function putTargetId(id, data) {
  const url = `${API_URL}/target_audiences/${id}`;
  return Axios.put(url, data);
}

export function deleteTargetId(data) {
  const url = `${API_URL}/target_audiences/${data}`;
  return Axios.delete(url);
}

// Channels
export function getChannels(data) {
  const url = `${API_URL}/channels`;
  return Axios.get(url, { params: data });
}

export function postChannels(data) {
  const url = `${API_URL}/channels`;
  return Axios.post(url, data);
}

export function getChannelsId(data) {
  const url = `${API_URL}/channels/${data}`;
  return Axios.get(url);
}

export function putChannelsId(id, data) {
  const url = `${API_URL}/channels/${id}`;
  return Axios.put(url, data);
}

export function deleteChannelsId(data) {
  const url = `${API_URL}/channels/${data}`;
  return Axios.delete(url);
}

export function getChannelsSettings(id, year) {
  const url = `${API_URL}/channels/${id}/settings/${year}`;
  return Axios.get(url);
}

export function putRestoreChannelsId(id) {
  const url = `${API_URL}/channels/${id}`;
  return Axios.put(url);
}

// Channel companies
export function getChannelcompany(data) {
  const url = `${API_URL}/channel_companies`;
  return Axios.get(url, { params: data });
}

export function postChannelcompany(data) {
  const url = `${API_URL}/channel_companies`;
  return Axios.post(url, data);
}

export function getChannelcompanyId(data) {
  const url = `${API_URL}/channel_companies/${data}`;
  return Axios.get(url);
}

export function putChannelcompanyId(id, data) {
  const url = `${API_URL}/channel_companies/${id}`;
  return Axios.put(url, data);
}

export function deleteChannelcompanyId(data) {
  const url = `${API_URL}/channel_companies/${data}`;
  return Axios.delete(url);
}

// year

export function getYear() {
  const url = `${API_URL}/years`;
  return Axios.get(url);
}

// brand
export function getBrands(data) {
  const url = `${API_URL}/brands`;
  return Axios.get(url, { params: data });
}

export function postBrands(data) {
  const url = `${API_URL}/brands`;
  return Axios.post(url, data);
}

export function putBrandsId(id, data) {
  const url = `${API_URL}/brands/${id}`;
  return Axios.put(url, data);
}

export function getBrandsId(id) {
  const url = `${API_URL}/brands/${id}`;
  return Axios.get(url);
}

export function deleteBrandsId(data) {
  const url = `${API_URL}/brands/${data}`;
  return Axios.delete(url);
}

//Genres
export function getGenres(data) {
  const url = `${API_URL}/genres`;
  return Axios.get(url, { params: data });
}

export function postGenres(data) {
  const url = `${API_URL}/genres`;
  return Axios.post(url, data);
}

export function getGenresId(data) {
  const url = `${API_URL}/genres/${data}`;
  return Axios.get(url);
}

export function putGenresId(id, data) {
  const url = `${API_URL}/genres/${id}`;
  return Axios.put(url, data);
}

export function deleteGenresId(data) {
  const url = `${API_URL}/genres/${data}`;
  return Axios.delete(url);
}

//Programs
export function getPrograms(data) {
  const url = `${API_URL}/programs`;
  return Axios.get(url, { params: data });
}

export function postPrograms(data) {
  const url = `${API_URL}/programs`;
  return Axios.post(url, data);
}

export function getProgramsId(data) {
  const url = `${API_URL}/programs/${data}`;
  return Axios.get(url);
}

export function putProgramsId(id, data) {
  const url = `${API_URL}/programs/${id}`;
  return Axios.put(url, data);
}

export function deleteProgramsId(data) {
  const url = `${API_URL}/programs/${data}`;
  return Axios.delete(url);
}

//CommVerTypes
export function getCommercialVersionTypes(data) {
  const url = `${API_URL}/commercial_version_types`;
  return Axios.get(url, { params: data });
}

export function postCommercialVersionTypes(data) {
  const url = `${API_URL}/commercial_version_types`;
  return Axios.post(url, data);
}

export function getCommercialVersionTypesId(data) {
  const url = `${API_URL}/commercial_version_types/${data}`;
  return Axios.get(url);
}

export function putCommercialVersionTypesId(id, data) {
  const url = `${API_URL}/commercial_version_types/${id}`;
  return Axios.put(url, data);
}

export function deleteCommercialVersionTypesId(data) {
  const url = `${API_URL}/commercial_version_types/${data}`;
  return Axios.delete(url);
}

//DiscountTypes
export function getDiscountTypes(data) {
  const url = `${API_URL}/discount_types`;
  return Axios.get(url, { params: data });
}

export function postDiscountTypes(data) {
  const url = `${API_URL}/discount_types`;
  return Axios.post(url, data);
}

export function getDiscountTypesId(data) {
  const url = `${API_URL}/discount_types/${data}`;
  return Axios.get(url);
}

export function putDiscountTypesId(id, data) {
  const url = `${API_URL}/discount_types/${id}`;
  return Axios.put(url, data);
}

export function deleteDiscountTypesId(data) {
  const url = `${API_URL}/discount_types/${data}`;
  return Axios.delete(url);
}

//PremiumPositionDiscounts
export function getPremiumPositionDiscounts(data) {
  const url = `${API_URL}/premium_position_discounts`;
  return Axios.get(url, { params: data });
}

export function postPremiumPositionDiscounts(data) {
  const url = `${API_URL}/premium_position_discounts`;
  return Axios.post(url, data);
}

export function getPremiumPositionDiscountsId(data) {
  const url = `${API_URL}/premium_position_discounts/${data}`;
  return Axios.get(url);
}

export function putPremiumPositionDiscountsId(id, data) {
  const url = `${API_URL}/premium_position_discounts/${id}`;
  return Axios.put(url, data);
}

export function deletePremiumPositionDiscountsId(data) {
  const url = `${API_URL}/premium_position_discounts/${data}`;
  return Axios.delete(url);
}

//Blocks
export function getBlocks(data) {
  const url = `${API_URL}/blocks`;
  return Axios.get(url, { params: data });
}

export function postBlocks(data) {
  const url = `${API_URL}/blocks`;
  return Axios.post(url, data);
}

export function getBlocksId(data) {
  const url = `${API_URL}/blocks/${data}`;
  return Axios.get(url);
}

export function putBlocksId(id, data) {
  const url = `${API_URL}/blocks/${id}`;
  return Axios.put(url, data);
}

export function deleteBlocksId(data) {
  const url = `${API_URL}/blocks/${data}`;
  return Axios.delete(url);
}

//Spots in Block for Booking
export function postBlocksIdCommercial(id, data) {
  const url = `${API_URL}/blocks/${id}/spots`;
  return Axios.post(url, data);
}
export function deleteBlocksIdSpots(id) {
  const url = `${API_URL}/blocks/${id}/spots`;
  return Axios.delete(url);
}

export function postBlocksDoubleSpot(id, data) {
  const url = `${API_URL}/blocks/${id}/double_spots`;
  return Axios.post(url, data);
}

export function deleteBlocksIdCommercial(id, data) {
  const url = `${API_URL}/blocks/${id}/spots/${data}`;
  return Axios.delete(url);
}

export function postUpSpot(blockId, spotId, data) {
  const url = `${API_URL}/blocks/${blockId}/spots/${spotId}/up`;
  return Axios.post(url, data);
}

export function postDownSpot(blockId, spotId, data) {
  const url = `${API_URL}/blocks/${blockId}/spots/${spotId}/down`;
  return Axios.post(url, data);
}

export function postFixSpot(blockId, spotId, data) {
  const url = `${API_URL}/blocks/${blockId}/spots/${spotId}/fix`;
  return Axios.post(url, data);
}

export function postUnfixSpot(blockId, spotId) {
  const url = `${API_URL}/blocks/${blockId}/spots/${spotId}/unfix`;
  return Axios.post(url);
}

//Get spot list for block
export function getBlockSpots(id) {
  const url = `${API_URL}/blocks/${id}/booking`;
  return Axios.get(url);
}

//Commercial Type
export function getCommercialType(data) {
  const url = `${API_URL}/commercial_types`;
  return Axios.get(url, { params: data });
}

//Block Type
export function getBlockTypes(data) {
  const url = `${API_URL}/block_types`;
  return Axios.get(url, { params: data });
}

// Broadcast
export function getBroadcastId(data, params) {
  const url = `${API_URL}/channels/${Object.values(data)}/broadcasting`;
  return Axios.get(url, { params: params });
}
export function getBroadcastingSettings(id, params) {
  const url = `${API_URL}/channels/${id}/broadcasting/settings`;
  return Axios.get(url, { params: params });
}
export function postBroadcastCopyDay(data, params) {
  const url = `${API_URL}/channels/${Object.values(data)}/broadcasting/copy-day`;
  return Axios.post(url, { ...params });
}
export function postBroadcastCopyProgramRelease(channel_id, pr_release_id, params) {
  const url = `${API_URL}/channels/${channel_id}/broadcasting/copy-program-release/${pr_release_id}`;
  return Axios.post(url, params);
}
export function postBroadcastCopyWeek(data, params) {
  const url = `${API_URL}/channels/${Object.values(data)}/broadcasting/copy`;
  return Axios.post(url, { ...params });
}
export function postBroadcastDeleteDay(data, params) {
  const url = `${API_URL}/channels/${Object.values(data)}/broadcasting/delete-day`;
  return Axios.post(url, { ...params });
}
export function postBroadcastDeleteWeek(data, params) {
  const url = `${API_URL}/channels/${Object.values(data)}/broadcasting/delete`;
  return Axios.post(url, { ...params });
}
export function patchCloseDays(channel_id, params) {
  const url = `${API_URL}/channels/${channel_id}/broadcasting/close-days`;
  return Axios.patch(url, { ...params });
}
export function patchOpenDays(channel_id, params) {
  const url = `${API_URL}/channels/${channel_id}/broadcasting/open-days`;
  return Axios.patch(url, { ...params });
}

export function patchBroadcastingDisallowEjection(channel_id, params) {
  const url = `${API_URL}/channels/${channel_id}/broadcasting/disallow-ejection`;
  return Axios.patch(url, { ...params });
}
export function patchBroadcastingAllowEjection(channel_id, params) {
  const url = `${API_URL}/channels/${channel_id}/broadcasting/allow-ejection`;
  return Axios.patch(url, { ...params });
}

export function postPreparationDay(channelId, data) {
  const url = `${API_URL}/channels/${channelId}/broadcasting/pre-broadcast-preparation`;
  return Axios.post(url, data);
}

export function getOneDayBroadcast(id, params) {
  const url = `${API_URL}/channels/${id}/broadcasting/one-day`;
  return Axios.get(url, { params: params });
}

export function postBroadcastOpenCloseMonth(params) {
  const url = `${API_URL}/broadcasting-closed-months`;
  return Axios.patch(url, params);
}

export function getBroadcastClosedMonths(params) {
  const url = `${API_URL}/broadcasting-closed-months`;
  return Axios.get(url, { params: params });
}

//Program Releases
export function getProgramReleases(data) {
  const url = `${API_URL}/program_releases`;
  return Axios.get(url, { params: data });
}

export function getProgramReleasesDistinctNames(data) {
  const url = `${API_URL}/program_releases/distinct-names`;
  return Axios.get(url, { params: data });
}

export function postProgramReleases(data) {
  const url = `${API_URL}/program_releases`;
  return Axios.post(url, data);
}

export function getProgramReleasesId(data) {
  const url = `${API_URL}/program_releases/${data}`;
  return Axios.get(url);
}

export function putProgramReleasesId(id, data) {
  const url = `${API_URL}/program_releases/${id}`;
  return Axios.put(url, data);
}

export function deleteProgramReleasesId(data) {
  const url = `${API_URL}/program_releases/${data}`;
  return Axios.delete(url);
}

//Projects
export function getProjects(data) {
  const url = `${API_URL}/projects`;
  return Axios.get(url, { params: data });
}

export function postProjects(data) {
  const url = `${API_URL}/projects`;
  return Axios.post(url, data);
}

export function getProjectsId(id, data) {
  const url = `${API_URL}/projects/${id}`;
  return Axios.get(url, { params: data });
}

export function getProjectAutomaticMediaplanPrograms(id, data) {
  const url = `${API_URL}/projects/${id}/automatic_mediaplan_programs`;
  return Axios.get(url, { params: data });
}

export function getProjectPremiumPositionDiscounts(id, data) {
  const url = `${API_URL}/projects/${id}/premium_position_discounts`;
  return Axios.get(url, { param: data });
}

export function putProjectsId(id, data) {
  const url = `${API_URL}/projects/${id}`;
  return Axios.put(url, data);
}

export function deleteProjectsId(data) {
  const url = `${API_URL}/projects/${data}`;
  return Axios.delete(url);
}

export function putProjectsRestoreId(id) {
  const url = `${API_URL}/projects/${id}/restore`;
  return Axios.put(url);
}

//Orders
export function getOrders(data) {
  const url = `${API_URL}/orders`;
  return Axios.get(url, { params: data });
}

export function postOrders(data) {
  const url = `${API_URL}/orders`;
  return Axios.post(url, data);
}

export function getOrdersId(id, data) {
  const url = `${API_URL}/orders/${id}`;
  return Axios.get(url, { params: data });
}

export function putOrdersId(id, data) {
  const url = `${API_URL}/orders/${id}`;
  return Axios.put(url, data);
}

export function deleteOrdersId(data) {
  const url = `${API_URL}/orders/${data}`;
  return Axios.delete(url);
}

export function putOrdersRestoreId(id) {
  const url = `${API_URL}/orders/${id}/restore`;
  return Axios.put(url);
}

//Media plans
export function getMediaPlans(data) {
  const url = `${API_URL}/mediaplans`;
  return Axios.get(url, { params: data });
}

export function getMediaPlansSummary(data) {
  const url = `${API_URL}/mediaplans/summary`;
  return Axios.get(url, { params: data });
}

export function postMediaPlans(data) {
  const url = `${API_URL}/mediaplans`;
  return Axios.post(url, data);
}

export function getMediaPlansId(id, data) {
  const url = `${API_URL}/mediaplans/${id}`;
  return Axios.get(url, { params: data });
}

export function putMediaPlansId(id, data) {
  const url = `${API_URL}/mediaplans/${id}`;
  return Axios.put(url, data);
}

export function deleteMediaPlansId(data) {
  const url = `${API_URL}/mediaplans/${data}`;
  return Axios.delete(url);
}

export function putMediaPlansRestoreId(id) {
  const url = `${API_URL}/mediaplans/${id}/restore`;
  return Axios.put(url);
}

export function getMediaPlanIdTable(id, data) {
  const url = `${API_URL}/mediaplans/${id}/table-data`;
  return Axios.get(url, { params: data });
}

export function calculateWgrpAndBudgets(mediaplan_id) {
  const url = `${API_URL}/mediaplans/${mediaplan_id}/calculate-wgrp-and-budgets`;
  return Axios.post(url);
}

export function calculateAllWgrpAndBudgets() {
  const url = `${API_URL}/mediaplans/calculate-wgrp-and-budget-all`;
  return Axios.post(url);
}

export function getMediaPlansAutomatic(data) {
  const url = `${API_URL}/mediaplans/automatic`;
  return Axios.get(url, { params: data });
}

export function postMediaPlansAutomaticExecute(data) {
  const url = `${API_URL}/mediaplans/automatic/execute`;
  return Axios.post(url, data);
}

export function getMediaPlanIdWGRP(id, data) {
  const url = `${API_URL}/mediaplans/${id}/wgrp`;
  return Axios.get(url, { params: data });
}

//Placement Type
export function getPlacementType(data) {
  const url = `${API_URL}/placement_types`;
  return Axios.get(url, { params: data });
}

//Commercials
export function getCommercials(data) {
  const url = `${API_URL}/commercials`;
  return Axios.get(url, { params: data });
}

export function postCommercials(data) {
  const url = `${API_URL}/commercials`;
  return Axios.post(url, data);
}

export function getCommercialsId(id, data) {
  const url = `${API_URL}/commercials/${id}`;
  return Axios.get(url, { params: data });
}

export function putCommercialsId(id, data) {
  const url = `${API_URL}/commercials/${id}`;
  return Axios.put(url, data);
}

export function deleteCommercialsId(data) {
  const url = `${API_URL}/commercials/${data}`;
  return Axios.delete(url);
}

export function putCommercialsRestoreId(id) {
  const url = `${API_URL}/commercials/${id}/restore`;
  return Axios.put(url);
}

export function postDetachCommercialsFromChannel(channel_id, data) {
  const url = `${API_URL}/channels/${channel_id}/detach-commercials`;
  return Axios.post(url, data);
}

//Discounts in Project
export function deleteDiscountsId(data) {
  const url = `${API_URL}/discounts/${data}`;
  return Axios.delete(url);
}

export function putDiscountsRestoreId(id) {
  const url = `${API_URL}/discounts/${id}/restore`;
  return Axios.put(url);
}

//PlacementProhibition
export function getPlacementProhibitions(data) {
  const url = `${API_URL}/placement_prohibitions`;
  return Axios.get(url, { params: data });
}

export function postPlacementProhibitions(data) {
  const url = `${API_URL}/placement_prohibitions`;
  return Axios.post(url, data);
}

export function getPlacementProhibitionsId(id, data) {
  const url = `${API_URL}/placement_prohibitions/${id}`;
  return Axios.get(url, { params: data });
}

export function putPlacementProhibitionsId(id, data) {
  const url = `${API_URL}/placement_prohibitions/${id}`;
  return Axios.put(url, data);
}

export function deletePlacementProhibitionsId(data) {
  const url = `${API_URL}/placement_prohibitions/${data}`;
  return Axios.delete(url);
}

//CoPlacementProhibition
export function getCoPlacementProhibitions(data) {
  const url = `${API_URL}/co_placement_prohibitions`;
  return Axios.get(url, { params: data });
}

export function postCoPlacementProhibitions(data) {
  const url = `${API_URL}/co_placement_prohibitions`;
  return Axios.post(url, data);
}

export function getCoPlacementProhibitionsId(id, data) {
  const url = `${API_URL}/co_placement_prohibitions/${id}`;
  return Axios.get(url, { params: data });
}

export function putCoPlacementProhibitionsId(id, data) {
  const url = `${API_URL}/co_placement_prohibitions/${id}`;
  return Axios.put(url, data);
}

export function deleteCoPlacementProhibitionsId(data) {
  const url = `${API_URL}/co_placement_prohibitions/${data}`;
  return Axios.delete(url);
}

// Booking
export function getBookingId(data, params) {
  const url = `${API_URL}/channels/${Object.values(data)}/booking`;
  return Axios.get(url, { params: params });
}

export function getOneDayBooking(id, params) {
  const url = `${API_URL}/channels/${id}/booking/one-day`;
  return Axios.get(url, { params: params });
}

export function postCopyBooking(id, data) {
  const url = `${API_URL}/channels/${id}/booking/copy`;
  return Axios.post(url, data);
}

export function deleteClearQueue(id, data) {
  const url = `${API_URL}/channels/${id}/booking/drop-queues`;
  return Axios.delete(url, { data: data });
}

// Marketing
export function getMarketing(data, params) {
  const url = `${API_URL}/channels/${Object.values(data)}/marketing`;
  return Axios.get(url, { params: params });
}

//Spots
export function postReplaceSpots(data) {
  const url = `${API_URL}/spots/replace`;
  return Axios.post(url, data);
}

export function putIncreaseBid(blockId, spotId, data) {
  const url = `${API_URL}/blocks/${blockId}/spots/${spotId}/raise-bid`;
  return Axios.put(url, data);
}

export function postDeleteSpots(data) {
  const url = `${API_URL}/spots/delete`;
  return Axios.post(url, data);
}

export function postFixSpots(data) {
  const url = `${API_URL}/spots/fix`;
  return Axios.post(url, data);
}

export function postSwapSpot(data) {
  const url = `${API_URL}/spots/swap`;
  return Axios.post(url, data);
}

//Price projects
export function getPriceProjects(data) {
  const url = `${API_URL}/price-projects`;
  return Axios.get(url, { params: data });
}

export function postPriceProjects(data) {
  const url = `${API_URL}/price-projects`;
  return Axios.post(url, data);
}

export function getPriceProjectsId(id, data) {
  const url = `${API_URL}/price-projects/${id}`;
  return Axios.get(url, { params: data });
}

export function putPriceProjectsId(id, data) {
  const url = `${API_URL}/price-projects/${id}`;
  return Axios.put(url, data);
}

export function deletePriceProjectsId(id) {
  const url = `${API_URL}/price-projects/${id}`;
  return Axios.delete(url);
}

// GRP RATINGS
export function postGrpUpload(data) {
  const url = `${API_URL}/grps/upload`;
  return Axios.post(url, data);
}

export function getBlockGrps(id, data) {
  const url = `${API_URL}/grps/${id}`;
  return Axios.get(url, data);
}

export function putBlockGrps(data) {
  const url = `${API_URL}/grps`;
  return Axios.put(url, data);
}

export function postFactGrpUpload(data) {
  const url = `${API_URL}/fact-grps/upload`;
  return Axios.post(url, data);
}

export function putUpdateSpotFactGrps(data) {
  const url = `${API_URL}/fact-grps/update-spot`;
  return Axios.put(url, data);
}

export function getBindFactGrps(data) {
  const url = `${API_URL}/fact-grps/bind`;
  return Axios.get(url, { params: data });
}

export function getCheckImportsFact(data) {
  const url = `${API_URL}/fact-grps/check-upload-status`;
  return Axios.get(url, { params: data });
}

export function getFactGrps(id, data) {
  const url = `${API_URL}/fact-grps/${id}`;
  return Axios.get(url, data);
}

export function unbindAllSpotsInDay(broadcastDay, channelId, data) {
  const url = `${API_URL}/fact-grps/${broadcastDay}/${channelId}`;
  return Axios.delete(url, data);
}

export function postCopyGrpDay(data) {
  const url = `${API_URL}/grps/copy-day`;
  return Axios.post(url, data);
}

export function putUnbindSpotFactGrps(data) {
  const url = `${API_URL}/fact-grps/unbind`;
  return Axios.put(url, data);
}

export function postBindFactByExternalId(data) {
  const url = `${API_URL}/fact-grps/bind-by-external-id`;
  return Axios.post(url, data);
}

export function postFactGrpBind(data) {
  const url = `${API_URL}/fact-grps/bind`;
  return Axios.post(url, data);
}

// ROLES
export function getRoles(data) {
  const url = `${API_URL}/roles`;
  return Axios.get(url, { params: data });
}

export function getRolesId(id, data) {
  const url = `${API_URL}/roles/${id}`;
  return Axios.get(url, { params: data });
}

// PERMISSIONS
export function getPermissions(data) {
  const url = `${API_URL}/permissions`;
  return Axios.get(url, { params: data });
}

//Update permission for the role
export function putPermissionsId(id, data) {
  const url = `${API_URL}/permissions/${id}`;
  return Axios.put(url, data);
}

//Get user permissions and role
export function getUserPermissionsId(id, data) {
  const url = `${API_URL}/permissions/user/${id}`;
  return Axios.get(url, { params: data });
}

//Update permission for user
export function putUserPermissionsId(id, data) {
  const url = `${API_URL}/permissions/user/${id}`;
  return Axios.put(url, data);
}

//Set role(?) for user
export function postUserPermissionsId(id, data) {
  const url = `${API_URL}/permissions/user/${id}`;
  return Axios.post(url, data);
}

//Set permissions to models by id
export function postPermissionsPermissionsAdd(data) {
  const url = `${API_URL}/permissions/permissions/add`;
  return Axios.post(url, data);
}

//Revoke direct permission
export function postPermissionsPermissionsRevoke(data) {
  const url = `${API_URL}/permissions/permissions/revoke`;
  return Axios.post(url, data);
}

//Exports
export function getExportBroadcast(id, params) {
  const url = `${API_URL}/exports/channels/${id}/broadcasting`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getMediaPlansExport(mediaplan_id, data) {
  const url = `${API_URL}/exports/mediaplans/${mediaplan_id}/table`;
  return Axios.get(url, { params: data, responseType: 'blob' });
}
export function getMediaPlansExportList(mediaplan_id, data) {
  const url = `${API_URL}/exports/mediaplans/${mediaplan_id}/list`;
  return Axios.get(url, { params: data, responseType: 'blob' });
}
export function getMediaPlansSummaryExport(data) {
  const url = `${API_URL}/exports/mediaplans/summary`;
  return Axios.get(url, { params: data, responseType: 'blob' });
}

export function getExportBooking(id, params) {
  const url = `${API_URL}/exports/channels/${id}/booking`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportBookingOneDay(id, type, params) {
  const url = `${API_URL}/exports/channels/${id}/booking-one-day${type ? '/' + type : ''}`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportFactImportSpots(params) {
  const url = `${API_URL}/exports/plan-grps`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportFactGrps(params) {
  const url = `${API_URL}/exports/fact-grps`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportMarketing(id, params) {
  const url = `${API_URL}/exports/channels/${id}/marketing`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportCosts(params) {
  const url = `${API_URL}/exports/costs`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getStreamCosts(data) {
  const url = `${API_URL}/stream/costs`;
  return Axios.get(url, { params: data });
}

export function getExportBroadcastReference(params) {
  const url = `${API_URL}/exports/broadcast-reference`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportObligationExecutions(params) {
  const url = `${API_URL}/exports/obligation-executions`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportClientsAdvertisers(params) {
  const url = `${API_URL}/exports/clients-and-advertisers`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}
export function getExportSalesPlanExecutions(params) {
  const url = `${API_URL}/exports/sales-plan-executions`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportByProjects(params) {
  const url = `${API_URL}/exports/projects`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportByProjectsShort(params) {
  const url = `${API_URL}/exports/projects-short`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportInventory(params) {
  const url = `${API_URL}/exports/inventory`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportPrograms(params) {
  const url = `${API_URL}/exports/programs`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportInventoryExtended(params) {
  const url = `${API_URL}/exports/inventory-extended`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportCategorySummary(params) {
  const url = `${API_URL}/exports/category-summary`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

export function getExportMMRKazakhstan(params) {
  const url = `${API_URL}/exports/mmr-kazakhstan`;
  return Axios.get(url, { params: params, responseType: 'blob' });
}

// Reports
export function getReportCosts(data) {
  const url = `${API_URL}/reports/costs`;
  return Axios.get(url, { params: data });
}

export function getReportBroadcastReference(data) {
  const url = `${API_URL}/reports/broadcast-reference`;
  return Axios.get(url, { params: data });
}

export function getReportObligationExecutions(data) {
  const url = `${API_URL}/reports/obligation-executions`;
  return Axios.get(url, { params: data });
}

export function getReportClientsAdvertisers(data) {
  const url = `${API_URL}/reports/clients-and-advertisers`;
  return Axios.get(url, { params: data });
}

export function getReportSalesPlanExecutions(data) {
  const url = `${API_URL}/reports/sales-plan-executions`;
  return Axios.get(url, { params: data });
}

export function getReportInventory(data) {
  const url = `${API_URL}/reports/inventory`;
  return Axios.get(url, { params: data });
}

export function getReportByProjects(data) {
  const url = `${API_URL}/reports/projects`;
  return Axios.get(url, { params: data });
}

export function getReportByProjectsShort(data) {
  const url = `${API_URL}/reports/projects-short`;
  return Axios.get(url, { params: data });
}

export function getReportPrograms(data) {
  const url = `${API_URL}/reports/programs`;
  return Axios.get(url, { params: data });
}

export function getReportInventoryExtended(data) {
  const url = `${API_URL}/reports/inventory-extended`;
  return Axios.get(url, { params: data });
}

export function getReportCategorySummary(data) {
  const url = `${API_URL}/reports/category-summary`;
  return Axios.get(url, { params: data });
}

export function getReportMMRKazakhstan(data) {
  const url = `${API_URL}/reports/mmr-kazakhstan`;
  return Axios.get(url, { params: data });
}

export function putCalendarPlans(data) {
  const url = `${API_URL}/calendar-plans`;
  return Axios.put(url, data);
}

// Check the current state of the global stop mode
export function getGlobalStop() {
  const url = `${API_URL}/global-stop`;
  return Axios.get(url);
}

//Enable global stop
export function postGlobalStop() {
  const url = `${API_URL}/global-stop`;
  return Axios.post(url);
}

//Disable global stop
export function deleteGlobalStop() {
  const url = `${API_URL}/global-stop`;
  return Axios.delete(url);
}

//Logs and requests
export function getLogs(data) {
  const url = `${API_URL}/logs`;
  return Axios.get(url, { params: data });
}
export function getLogId(id, params) {
  const url = `${API_URL}/logs/${id}`;
  return Axios.get(url, { params });
}
export function getRequests(data) {
  const url = `${API_URL}/requests`;
  return Axios.get(url, { params: data });
}
export function getRequestId(id, params) {
  const url = `${API_URL}/requests/${id}`;
  return Axios.get(url, { params });
}

//Holdings
export function getHoldings(data) {
  const url = `${API_URL}/holdings`;
  return Axios.get(url, { params: data });
}

export function postHoldings(data) {
  const url = `${API_URL}/holdings`;
  return Axios.post(url, data);
}

export function getHoldingsId(data) {
  const url = `${API_URL}/holdings/${data}`;
  return Axios.get(url);
}

export function putHoldingsId(id, data) {
  const url = `${API_URL}/holdings/${id}`;
  return Axios.put(url, data);
}

export function deleteHoldingsId(data) {
  const url = `${API_URL}/holdings/${data}`;
  return Axios.delete(url);
}

//retro bonus scales
export function getRetroBonusScale(data) {
  const url = `${API_URL}/retro-bonus-scales`;
  return Axios.get(url, { params: data });
}
export function postRetroBonusScaleUpload(data) {
  const url = `${API_URL}/retro-bonus-scales`;
  return Axios.post(url, data);
}

//retro bonus discounts
export function getRetroBonusDiscounts(data) {
  const url = `${API_URL}/retro-bonus-discounts`;
  return Axios.get(url, { params: data });
}
export function postRetroBonusApprove(data) {
  const url = `${API_URL}/retro-bonus-discounts`;
  return Axios.post(url, data);
}
