import Vue from 'vue';
import Vuex from 'vuex';
import agencies from './agencies/index';
import holdings from './holdings/index';
import advertisers from './advertisers/index';
import classes from './classes/index';
import group from './group/index';
import salehouses from './salehouses/index';
import target from './target/index';
import measurements from './measurements/index';
import channels from './channels/index';
import channelcompany from './channelcompany/index';
import year from './year/index';
import brands from './brands/index';
import genres from './genres/index';
import programs from './programs/index';
import commercialversiontypes from './commercialversiontypes/index';
import commercialtype from './commercialtype/index';
import discounttypes from './discounttypes/index';
import premiumpositiondiscounts from './premiumpositiondiscounts/index';
import blocks from './blocks/index';
import broadcast from './broadcast/index';
import auth from './auth/index';
import programreleases from './programreleases/index';
import projects from './projects/index';
import orders from './orders/index';
import mediaplans from './mediaplans/index';
import users from './users/index';
import placementTypes from './placementTypes/index';
import commercials from './commercials/index';
import discounts from './discounts/index';
import placementProhibition from './placementProhibition/index';
import coPlacementProhibition from './coPlacementProhibition/index';
import booking from './booking/index';
import spots from './spots/index';
import marketing from './marketing/index';
import priceProjects from './priceProjects/index';
import grp from './grp/index';
import roles from './roles/index';
import permissions from './permissions/index';
import blocktypes from './blocktypes/index';
import retroBonusScales from './retroBonusScales/index';
import retroBonusDiscounts from './RetroBonusDiscounts/index';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isTheme: localStorage.getItem('theme'), //'true': string - dark
    isLocale: localStorage.getItem('locale'), //en, ru, vi...
    selectedSaleshouse: JSON.parse(localStorage.getItem('selectedSaleshouse')),
    broadcastChannel: null,
    isDev: process.env.VUE_APP_IS_DEV === 'TRUE' ? true : false,
    isExperimentalFeatures: process.env.VUE_APP_EXPERIMENTAL_FEATURES === 'TRUE' ? true : false,
    isShowSpotID: localStorage.getItem('showSpotID') === 'true',
    isShowProgramReleaseID: localStorage.getItem('showProgramReleaseID') === 'true',
    isShowSummaryMpID: localStorage.getItem('showSummaryMpID') === 'true',
    BROADCAST_START_TIME_IN_SEC: process.env.VUE_APP_BROADCAST_START_TIME ? +process.env.VUE_APP_BROADCAST_START_TIME : 21600, //default 06:00:00
    gridBroadcastingSettings: [],
  },
  mutations: {
    GET_CHANGE_THEME(state, payload) {
      Vue.set(state, 'isTheme', payload);
    },
    GET_CHANGE_LOCALE(state, payload) {
      Vue.set(state, 'isLocale', payload);
    },
    GET_CHANGE_SALESHOUSE(state, payload) {
      if (payload?.id) {
        Vue.set(state, 'selectedSaleshouse', payload);
        localStorage.setItem('selectedSaleshouse', JSON.stringify(payload));
      } else {
        Vue.set(state, 'selectedSaleshouse', null);
        localStorage.removeItem('selectedSaleshouse');
      }
    },
    CREATE_BROADCAST_CHANNEL(state) {
      if (state.broadcastChannel !== null) return;
      const apiVariable = typeof window.BroadcastChannel != 'undefined' ? new BroadcastChannel('OML_channel') : null;
      Vue.set(state, 'broadcastChannel', apiVariable);
    },
    toggleShowSpotID(state) {
      state.isShowSpotID = !state.isShowSpotID;
      localStorage.setItem('showSpotID', state.isShowSpotID);
      try {
        state.broadcastChannel?.postMessage({ type: 'update_isShowSpotID', isShowSpotID: state.isShowSpotID });
      } catch (e) {
        console.log('Error while sending BC message');
      }
    },
    toggleShowProgramReleaseID(state) {
      state.isShowProgramReleaseID = !state.isShowProgramReleaseID;
      localStorage.setItem('showProgramReleaseID', state.isShowProgramReleaseID);
      try {
        state.broadcastChannel?.postMessage({ type: 'update_isShowProgramReleaseID', isShowProgramReleaseID: state.isShowProgramReleaseID });
      } catch (e) {
        console.log('Error while sending BC message');
      }
    },
    toggleShowSummaryMpID(state) {
      state.isShowSummaryMpID = !state.isShowSummaryMpID;
      localStorage.setItem('showSummaryMpID', state.isShowSummaryMpID);
      try {
        state.broadcastChannel?.postMessage({ type: 'update_isShowSummaryMpID', isShowSummaryMpID: state.isShowSummaryMpID });
      } catch (e) {
        console.log('Error while sending BC message');
      }
    },
    // grid common day settings
    setGridBroadcastSettings(state, data) {
      state.gridBroadcastingSettings = data || [];
    },
    clearGridBroadcastingSettings(state) {
      state.gridBroadcastingSettings = [];
    },
    clearSelectedSaleshouse(state) {
      state.selectedSaleshouse = null;
      localStorage.removeItem('selectedSaleshouse');
    },
  },
  actions: {
    SET_CHANGE_THEME({ commit }, payload) {
      commit('GET_CHANGE_THEME', payload);
    },
    SET_CHANGE_LOCALE({ commit }, payload) {
      commit('GET_CHANGE_LOCALE', payload);
    },
    SET_CHANGE_SALESHOUSE({ commit }, payload) {
      commit('GET_CHANGE_SALESHOUSE', payload);
    },
  },
  getters: {
    isTheme: (state) => state.isTheme,
    isLocale: (state) => state.isLocale,
    getSelectedSaleshouse: (state) => state.selectedSaleshouse,
    getBroadcastChannel: (state) => state.broadcastChannel,
    getDevFlag: (state) => state.isDev,
    getExperimentalFeaturesFlag: (state) => state.isExperimentalFeatures,
    getShowSpotID: (state) => state.isShowSpotID,
    getShowProgramReleaseID: (state) => state.isShowProgramReleaseID,
    getShowSummaryMpID: (state) => state.isShowSummaryMpID,
    getBroadcastStartInterval: (state) => state.BROADCAST_START_TIME_IN_SEC,
    getGridBroadcastingSettings: (state) => state.gridBroadcastingSettings,
  },
  modules: {
    agencies,
    holdings,
    advertisers,
    classes,
    group,
    salehouses,
    target,
    measurements,
    channels,
    channelcompany,
    year,
    brands,
    genres,
    programs,
    commercialversiontypes,
    discounttypes,
    premiumpositiondiscounts,
    blocks,
    commercialtype,
    blocktypes,
    broadcast,
    programreleases,
    auth,
    projects,
    orders,
    mediaplans,
    users,
    placementTypes,
    commercials,
    discounts,
    placementProhibition,
    coPlacementProhibition,
    booking,
    spots,
    marketing,
    priceProjects,
    grp,
    roles,
    permissions,
    retroBonusScales,
    retroBonusDiscounts,
  },
});
