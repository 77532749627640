import Vue from 'vue';
import {
  BVConfigPlugin,
  BButton,
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  FormTimepickerPlugin,
  InputGroupPlugin,
  ModalPlugin,
  BIcon,
  BIconX,
  BIconList,
  BIconBoxArrowRight,
  BIconPencil,
  BIconTrash,
  BIconChevronDown,
  BIconChevronUp,
  BIconLock,
  BIconUnlock,
  BIconArrowRight,
  BIconArrowLeft,
  BIconEye,
  BIconEyeSlash,
  BIconArrowDown,
  BIconArrowUp,
  BIconPeople,
  BIconArrowLeftSquare,
  BIconArrowRightSquare,
  BIconArrowBarRight,
  BIconWrench,
  BIconFunnelFill,
  BIconFunnel,
  BIconPersonX,
  BIconPersonPlus,
  BIconPlus,
  BIconZoomIn,
  BIconZoomOut,
  //BIconTriangleFill,
  BIconCaretLeft,
  BIconCaretRight,
} from 'bootstrap-vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { i18n } from './i18n';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import Notifications from 'vue-notification';
import VueAxios from 'vue-axios';
import VueTheMask from 'vue-the-mask';
import VueCookies from 'vue-cookies';
import checkPermissions from '@/mixins/checkPermissions';

Vue.use(BVConfigPlugin, {
  BModal: {
    noCloseOnBackdrop: true,
    cancelVariant: 'outline-danger',
    titleTag: 'h5',
  },
  BFormDatepicker: {
    noFlip: true,
  },
  BButton: {
    variant: 'outline-danger',
  },
  BFormTimepicker: {
    buttonVariant: 'outline-secondary',
    showSeconds: true,
    closeButtonVariant: 'outline-danger',
  },
});
Vue.use(VueCookies);
Vue.use(VueAxios, axios);

Vue.component('b-button', BButton);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormTimepickerPlugin);
Vue.use(InputGroupPlugin);
Vue.use(ModalPlugin);

Vue.component('BIcon', BIcon);
Vue.component('BIconX', BIconX);
Vue.component('BIconList', BIconList);
Vue.component('BIconBoxArrowRight', BIconBoxArrowRight);
Vue.component('BIconPencil', BIconPencil);
Vue.component('BIconTrash', BIconTrash);
Vue.component('BIconChevronDown', BIconChevronDown);
Vue.component('BIconChevronUp', BIconChevronUp);
Vue.component('BIconLock', BIconLock);
Vue.component('BIconUnlock', BIconUnlock);
Vue.component('BIconArrowRight', BIconArrowRight);
Vue.component('BIconArrowLeft', BIconArrowLeft);
Vue.component('BIconEye', BIconEye);
Vue.component('BIconEyeSlash', BIconEyeSlash);
Vue.component('BIconArrowDown', BIconArrowDown);
Vue.component('BIconArrowUp', BIconArrowUp);
Vue.component('BIconPeople', BIconPeople);
Vue.component('BIconArrowLeftSquare', BIconArrowLeftSquare);
Vue.component('BIconArrowRightSquare', BIconArrowRightSquare);
Vue.component('BIconArrowBarRight', BIconArrowBarRight);
Vue.component('BIconWrench', BIconWrench);
Vue.component('BIconFunnelFill', BIconFunnelFill);
Vue.component('BIconFunnel', BIconFunnel);
Vue.component('BIconPersonX', BIconPersonX);
Vue.component('BIconPersonPlus', BIconPersonPlus);
Vue.component('BIconPlus', BIconPlus);
Vue.component('BIconZoomIn', BIconZoomIn);
Vue.component('BIconZoomOut', BIconZoomOut);
Vue.component('BIconCaretLeft', BIconCaretLeft);
Vue.component('BIconCaretRight', BIconCaretRight);

Vue.use(Vuelidate);
Vue.use(Notifications);
Vue.use(VueTheMask);
Vue.mixin(checkPermissions);

// Vue.directive('visible', function (el, binding, vnode) {
//   el.style.visibility = binding.value ? '' : 'hidden';
// });

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
